<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="companyData === undefined">
      <h4 class="alert-heading">Error fetching company data</h4>
      <div class="alert-body">
        No user found with this company id. Check
        <b-link class="alert-link" :to="{ name: 'apps-company-list' }">
          Company List
        </b-link>
        for other companies.
      </div>
    </b-alert>

    <template v-if="companyData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <company-view-info-card
            :company-data="companyData"
          />
        </b-col>
        
      </b-row>

      <b-row>
        <b-col cols="12">
          <company-view-detail
            :company-data="companyData"
          />
        </b-col>
        
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { BRow, BCol, BAlert, BLink, BTab, BTabs } from 'bootstrap-vue'
import companyStoreModule from '../companyStoreModule'
import CompanyViewInfoCard from './CompanyViewInfoCard.vue'
import CompanyViewDetail from './CompanyViewDetail.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTab,
    BTabs,

    // Local Components
    CompanyViewInfoCard,
    CompanyViewDetail,
  },
  setup() {
    const companyData = ref(null)
    const COMPANY_APP_STORE_MODULE_NAME = 'app-company'

    // Register module
    if (!store.hasModule(COMPANY_APP_STORE_MODULE_NAME)) store.registerModule(COMPANY_APP_STORE_MODULE_NAME, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_APP_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_APP_STORE_MODULE_NAME)
    })

    // store
    //   .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
    //   .then((response) => {
    //     userData.value = response.data.user
    //     userProducts.value = response.data.products
    //   })
    //   .catch((error) => {
    //     if (error.status === 404) {
    //       userData.value = undefined
    //       userProducts.value = undefined
    //     }
    //   })

    const fetchCompanyData = () => {
      console.log("start fetch comapny")
      store
        .dispatch('app-company/fetchCompany', { id: router.currentRoute.params.id })
        .then((response) => {
          companyData.value = response.data.company
          console.log("company" + JSON.stringify(companyData))
        })
        .catch((error) => {
          console.log(error)
          if (error.status === 404) {
            companyData.value = undefined
          }
        })
    }

    console.log("before fetch comapny")
    fetchCompanyData()

    return {
      companyData,
      fetchCompanyData,
    }
  },
}
</script>

<style></style>
