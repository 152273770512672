<template v-if="companyData">
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="4"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="companyData.logo_path"
            :text="avatarText(companyData.display_name)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ companyData.display_name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <!-- <b-button
                :to="{
                  name: 'apps-company-edit',
                  params: { id: companyData.company_id },
                }"
                variant="primary"
              >
                Edit
              </b-button> -->
              <a :href=" companyData.slug ? 'https://manufacturese.com/biz/'+ companyData.slug :'https://manufacturese.com/biz/id/'+ companyData.company_id" target="_blank" class="btn ml-1 btn-outline-primary">View on web</a>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="4">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Company ID</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ companyData.company_id }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ companyData.status == 0 ? 'Inactive' : 'Active' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Slug</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ companyData.slug ? companyData.slug : '-' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="GlobeIcon" class="mr-75" />
              <span class="font-weight-bold">HQ Location</span>
            </th>
            <td class="pb-50">
              {{
                companyData.hq_area ? companyData.hq_area : '-'
              }}
            </td>
          </tr>
        </table>
      </b-col>

      <b-col cols="12" xl="4">
        <b-row>
          <b-col cols="6">
            <p class="font-weight-bold mb-0">Created At</p>
            <p class="mb-1">
              {{
                companyData.created_at
                  ? new Date(companyData.created_at).toLocaleString()
                  : '-'
              }}
            </p>
          </b-col>
          <b-col cols="6">
            <p class="font-weight-bold mb-0">Created By</p>
            <p class="mb-1">
              {{ companyData.created_by ? companyData.created_by : '-' }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <p class="font-weight-bold mb-0">Updated At</p>
            <p class="mb-1">
              {{
                companyData.updated_at
                  ? new Date(companyData.updated_at).toLocaleString()
                  : '-'
              }}
            </p>
          </b-col>
          <b-col cols="6">
            <p class="font-weight-bold mb-0">Updated By</p>
            <p class="mb-1">
              {{ companyData.updated_by ? companyData.updated_by : '-' }}
            </p>
          </b-col>
        </b-row>
      </b-col>
        
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import useUsersList from '../users-list/useUsersList'
import router from '@/router'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BForm,
    BFormCheckbox,
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
    // fetchcompanyData: {
    //   type: Function,
    //   required: true,
    // },
  },
  setup() {  
    return {
      avatarText,
    }
  },
  // data() {
  //   const defaultValue = {
  //     status: this.companyData.status
  //   }
    
  //   return {
  //     form: Object.assign({}, defaultValue),
  //   }
  // },
  methods: {
    // toggleIndeterminate() {
    //   const data = {
    //     user_id: this.companyData.user_id,
    //     status: Number(this.form.status),
    //   }
    //   this.$store
    //     .dispatch('app-user/updateUser', {
    //       id: router.currentRoute.params.id,
    //       data: JSON.stringify(data),
    //     })
    //     .then((response) => {
    //       console.log(response)
    //       if (response.status == 200) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'The user status is successfully updated',
    //             icon: 'CheckIcon',
    //           },
    //         })
    //         this.fetchcompanyData()
    //       }
    //     })
    // },
  },
}
</script>

<style></style>
